import { Type, Position } from '~/src/components/generic/Toast/types'

export const typeColorMap: { [key in Type]: string } = {
  success: 'bg-green-50 text-green-700 border-green-600 border-2',
  error: 'bg-red-50 text-red-600 border-red-600 border-2',
  warning: '',
}

export const toastPositionMap: { [key in Position]: string } = {
  'top-right': 'top-5 right-5',
  'top-left': 'top-5 left-5',
  'bottom-right': 'bottom-5 right-5',
  'bottom-left': 'bottom-5 left-5',
}
