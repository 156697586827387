import { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import WarningCircleIcon from '~/src/components/generic/PhosphorIcons/WarningCircleIcon'
import CheckCircleIcon from '~/src/components/generic/PhosphorIcons/CheckCircleIcon'
import WarningIcon from '~/src/components/generic/PhosphorIcons/WarningIcon'
import XIcon from '~/src/components/generic/PhosphorIcons/XIcon'
import IconButton from '~/src/components/generic/IconButton'
import { ToastProps, Type } from '~/src/components/generic/Toast/types'
import { typeColorMap } from '~/src/components/generic/Toast/constants'
import { useToast } from '~/src/components/generic/Toast/hook/useToast'

import useTimeout from '~/hooks/useTimeout'

import colors from '~/config/colorsScheme'

type Props = {
  toast: ToastProps
  delay: number
  showCloseButton: boolean
  style?: { container: string; content: string }
}

const iconMap: { [key in Type]: ReactNode } = {
  error: <WarningCircleIcon size="24" color={colors.red[600]} />,
  success: <CheckCircleIcon size="24" color={colors.green[700]} />,
  warning: <WarningIcon size="24" color={colors.yellow[600]} />,
}

const Toast: FC<Props> = ({ toast, delay, showCloseButton, style }) => {
  const notify = useToast()

  const handleClose = () => {
    toast.onClose?.()
    notify.remove(toast.id)
  }

  useTimeout(handleClose, delay)

  return (
    <div
      role="alert"
      className={twMerge(
        'relative w-full rounded p-3 shadow-md',
        typeColorMap[toast.type],
        style?.container
      )}
    >
      <div className="flex items-start justify-between space-x-2">
        <div className={twMerge('flex space-x-2', style?.content)}>
          <div>{iconMap[toast.type]}</div>
          <p>{toast.message}</p>
        </div>
        {showCloseButton && (
          <IconButton
            variant="ghost"
            onClick={handleClose}
            aria-label="Close toast window"
          >
            <XIcon size="20" weight="bold" />
          </IconButton>
        )}
      </div>
    </div>
  )
}

export default Toast
